var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scene",class:_vm.viewCSSClasses,attrs:{"id":_vm.viewCSSId}},[_c('div',{staticClass:"section-foreground container"},[_c('div',{staticClass:"layout-column rows"},[_c('div',{staticClass:"row row-1 layout-row layout-align-center-center"},[_c('div',{staticClass:"col-1-1 layout-align-start-end layout-column"},[_c('section-title',{staticClass:"text-right parallax-item parallax-relative",attrs:{"partNo":_vm.part.id,"partTitle":_vm.part.title,"data-depth":"2.5","show":_vm.showTitle}})],1),_c('div',{staticClass:"col-1-2 layout-align-center-start layout-column col-padding"},[_c('lead',{staticClass:"parallax-item parallax-relative",attrs:{"show":_vm.showLead,"data-depth":"1.8","textAlign":'left',"timescale":1}},[_vm._v(_vm._s(_vm.data.meta.description))])],1)]),_c('div',{staticClass:"layout-row cols row-2 row layout-align-center-start"},[_c('div',{staticClass:"col-1 layout-column layout-align-start-center z-index-1"},[_c('diagram-fire',{attrs:{"show":_vm.showDiagram,"layers":_vm.diagramLayers,"timescaleReverse":2}})],1),_c('div',{staticClass:"col-2 layout-column layout-align-start-start z-index-2"},[_c('info-modal-box',{staticClass:"\n              parallax-item parallax-relative\n              section-infobox\n              flex-nogrow\n            ",attrs:{"id":"box-economy","show":_vm.showInfoBoxes,"data-depth":"3.4","data":_vm.data.boxes.info_1}})],1)])])]),_c('background-image-full',{attrs:{"image":_vm.data.images.background,"show":_vm.showBackground,"imageCss":{
      objectPosition: 'center bottom',
      objectFit: 'cover',
      width: '113%',
      height: '102%',
      bottom: '-5%',
      right: '-12%',
    },"mask":true,"maskOpacity":1,"timescaleReverse":2.8,"timescale":0.9}}),_vm._m(0),_vm._l((_vm.data.modals),function(modal){return _c('modal-main',{key:modal.name,attrs:{"data":modal}})})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container fluid fixed fire-mask-wrap"},[_c('div',{staticClass:"fire-mask"})])}]

export { render, staticRenderFns }