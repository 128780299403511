<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-column rows">
        <div class="row row-1 layout-row layout-align-center-center">
          <div class="col-1-1 layout-align-start-end layout-column">
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative"
              :show="showTitle"
            ></section-title>
          </div>
          <div
            class="col-1-2 layout-align-center-start layout-column col-padding"
          >
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="1.8"
              :textAlign="'left'"
              :timescale="1"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="layout-row cols row-2 row layout-align-center-start">
          <div class="col-1 layout-column layout-align-start-center z-index-1">
            <diagram-fire
              :show="showDiagram"
              :layers="diagramLayers"
              :timescaleReverse="2"
            ></diagram-fire>
          </div>
          <div class="col-2 layout-column layout-align-start-start z-index-2">
            <info-modal-box
              id="box-economy"
              :show="showInfoBoxes"
              class="
                parallax-item parallax-relative
                section-infobox
                flex-nogrow
              "
              data-depth="3.4"
              :data="data.boxes.info_1"
            >
            </info-modal-box>
          </div>
        </div>
      </div>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{
        objectPosition: 'center bottom',
        objectFit: 'cover',
        width: '113%',
        height: '102%',
        bottom: '-5%',
        right: '-12%',
      }"
      :mask="true"
      :maskOpacity="1"
      :timescaleReverse="2.8"
      :timescale="0.9"
    ></background-image-full>

    <div class="container fluid fixed fire-mask-wrap">
      <div class="fire-mask"></div>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Lead from "@/components/ui/Lead.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";

import DiagramFire from "@/views/chapters/3/DiagramFire.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-3-1",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    InfoModalBox,
    BackgroundImageFull,
    DiagramFire,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 300],
        ["showTitle", 300],
        ["showDiagram", 1500],
        ["showInfoBoxes", 500],
        ["showLead", 1600],
      ],
    };
  },
  computed: {
    diagramLayers() {
      let arr = this.data.diagramData.layers.map((item) => {
        return {
          id: item.id,
          src: `${this.$urls.img}/diagrams/fire/${item.id}.png`,
          zIndex: item.zIndex,
          depth: item.depth,
          class: item.class,
        };
      });

      return arr.reverse();
    },
  },
};
</script>

<style scoped>
.view-part {
  padding-bottom: 0;
}
.col-1 {
  margin-top: -3.5rem;
  margin-left: -10%;
}
.col-2 {
  margin-left: -3rem;
}
.section-title {
  max-width: 10.5em;
}
/deep/ .lead {
  max-width: 31em;
}
#box-economy {
}
/deep/ .info-box {
  background: rgba(255, 255, 255, 0.7);
}

/deep/ .section-background-mask {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
/deep/ .section-nav-mask .section-footer-mask {
  background: rgba(255, 255, 255, 0);
}

/deep/ .section-background-img img {
  bottom: -1% !important;
}

.fire-mask {
  background: rgb(230, 243, 255);
  opacity: 0.6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(230, 243, 255, 1) 0%,
    rgba(230, 243, 255, 1) 70%,
    rgba(230, 243, 255, 0) 100%
  );
}

#diagram-fire {
  margin-top: -3rem;
  font-size: 1rem;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .col-1 {
    margin-top: -1rem;
  }
  .col-2 {
    margin-left: -6rem;
  }
  #diagram-fire {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .col-1 {
    margin-left: -8%;
  }
  .col-2 {
    margin-left: -4rem;
  }
  #diagram-fire {
    font-size: 0.8rem;
  }
  /deep/ .lead {
    max-width: 31em;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .portrait .row-1,
  .portrait .row-2 {
    flex-direction: column;
    padding: var(--col-padding);
  }
  .portrait .row-1 > *,
  .portrait .row-2 > * {
    max-width: 100%;
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .portrait .col-1 {
    margin: -2rem 0 7rem -2%;
    order: 2;
  }
  .portrait .col-2 {
    margin: 1rem 0 0;
    order: 1;
  }
  .portrait #diagram-fire {
    font-size: 1rem;
  }
  /deep/ .fire-diagram {
    max-width: 110%;
  }
}

@media screen and (max-width: 1023px) {
  .landscape .view-part {
    padding-bottom: calc(4 * var(--button-rem)) 0 calc(3.75 * var(--button-rem));
  }
}

@media screen and (max-width: 991px) {
  .row-1,
  .row-2 {
    flex-direction: column;
    padding: var(--col-padding);
  }
  .row-1 > *,
  .row-2 > * {
    max-width: 100%;
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .col-1 {
    margin: -2rem 0 4rem -2%;
    order: 2;
  }
  .portrait .col-1 {
    margin-bottom: 8rem;
  }
  .col-2 {
    margin: 1rem 0 0;
    order: 1;
  }
  #diagram-fire {
    font-size: 1rem;
  }
  /deep/ .fire-diagram {
    max-width: 110%;
  }
  .view-part {
    padding-bottom: calc(4 * var(--button-rem)) 0 calc(3.75 * var(--button-rem));
  }
}

@media screen and (max-width: 767px) {
  /deep/ .fire-diagram {
    max-width: 120%;
  }
  .col-1 {
    margin: 0 0 8rem -9.5%;
  }
  .portrait .col-1 {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 639px) {
  /deep/ .fire-diagram {
    max-width: 120%;
  }
  .col-1 {
    margin: 0 0 7rem -9.5%;
  }
}
@media screen and (max-width: 540px) {
  /deep/ .fire-diagram {
    max-width: 135%;
  }
  .col-1 {
    margin: 0 0 8rem -19%;
  }
}
@media screen and (max-width: 520px) {
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 420px) {
}

@media screen and (max-width: 390px) {
}
</style>
